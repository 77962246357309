<template>
  <div class="dashboard-container">
    <!-- 添加或编辑类别 -->
  

    <div class="app-container" style="margin-bottom: 20px">
      <el-row>
        <el-col :span="12" align="left">
          <div style="display: flex; width: 70%">
            <el-input
              v-model="searchtitle"
              placeholder="请输入活动名称"
              clearable
              @clear="clear"
            />
            <el-button
              slot="append"
              type="primary"
              style="margin-left: 5px"
              @click="searchTitle"
              >查询</el-button
            >
          </div>
        </el-col>
        <el-col :span="12" align="right">
          <el-button class="el-icon-edit" type="primary" @click="addActivity"
            >添加活动</el-button
          >
        </el-col>
      </el-row>
    </div>

    <!-- 主体内容 -->

    <div class="tab">
      <el-table
        :header-cell-style="{ background: '#F2F6FC', color: '#606266' }"
        :data="list"
        border
        style="width: 100%"
        ref="list"
        class="emp_table"
        v-if="list"
      >
        <el-table-column
          align="center"
          prop="logoId"
          label="活动海报"
          width="200"
        >
          <template slot-scope="scope">
            <div class="permitImg-img">
              <el-image
                style="width: 35%"
                :src="'http://file.innopinenut.com/' + scope.row.logoId"
                :preview-src-list="[
                  'http://file.innopinenut.com/' + scope.row.logoId
                ]"
              />
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="title"
          label="活动名称"
          :show-overflow-tooltip="true"
        />
        <el-table-column
          align="center"
          prop="originType"
          label="	来源类别"
          :show-overflow-tooltip="true"
        >
         <template slot-scope="scope">
            {{ scope.row.originType ==1? "外部链接":"本地内容" }}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="limitPersonNum"
          label="限制人数"
          :show-overflow-tooltip="true"
        />
        <el-table-column
          align="center"
          prop="signPersonNum"
          label="报名人数"
          :show-overflow-tooltip="true"
        />
        <el-table-column
          align="center"
          prop="endSignTime"
          label="报名截止时间"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            {{ scope.row.endSignTime | getLocalTime }}
          </template>
        </el-table-column>
        
        <el-table-column
          align="center"
          prop="viewNum"
          label="阅览数"
          :show-overflow-tooltip="true"
        />
        <el-table-column
          align="center"
          prop="upStatus"
          label="上下架状态"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            {{ scope.row.upStatus | fmatStatus }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作"  width="200px">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click="gotouser(scope.row)"
            >
              报名列表
            </el-button>
            <el-button
              type="text"
              size="small"
              @click="updateActivity(scope.row)"
            >
              编辑
            </el-button>
            <el-button
              type="text"
              size="small"
              @click="setActivityStatus(scope.row)"
            >
            {{ scope.row.upStatus == 1 ? "上架" : "下架" }}
            </el-button>

            <el-button type="text" size="small" @click="delActivity(scope.row)">
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <pagination
        v-show="total > 0"
        :total="total"
        :page.sync="queryParams.page"
        :limit.sync="queryParams.pageSize"
        @pagination="getActivityList"
      />
    </div>
  </div>
</template>
  
  <script>
import {
  getActivityList,
  delActivity,
  setActivityStatus,
  addActivity,
  updateActivity,
  getUploadToken
} from '../../request/http'

export default {
  data() {
    return {
      editOradd: null,
      imageUrl: '',
      postData: {
        token: '',
        key: '' //上传的Logo
      },
      title: '',
      searchtitle: '',
      showDialog: false,
      list: '',
      total: 0,
      queryParams: {
        page: 1,
        pageSize: 10
      },
      dialogVisible: false, //显示添加或编辑框
      formData: {
        title: '',
        logoId: '',
        content: ''
      },

      rules: {
        title: [
          { required: true, message: '活动名称不能为空', trigger: 'blur' }
        ],
        logoId: [
          { required: true, message: '活动海报不能为空', trigger: 'blur' }
        ],
        content: [
          { required: true, message: '活动内容不能为空', trigger: 'blur' }
        ]
      }
    }
  },

  created() {
    this.getActivityList()
  },
  mounted() {},
  methods: {
    gotouser(row){
      let activityId =row.activityId
            this.$router.push({
        path: "/activityUser",
        query: { activityId },
      }); 
    }
    ,
    clear() {
      this.queryParams = {}
      this.getActivityList()
      this.searchtitle = ''
    },
    // 查询活动标题
    async searchTitle() {
      this.queryParams = {
        page: 1,
        pageSize: 10,
        title: this.searchtitle
      }
      const { data, count } = await getActivityList(this.queryParams)
      this.list = data
      this.total = count
    },

    //设置上下架
    async setActivityStatus(val) {
      let id = val.activityId
      let { code, msg } = await setActivityStatus({ activityId: id })
      //   console.log(code);

      code == 0 &&
        this.$message({
          type: 'success',
          message: '设置成功'
        })
      code !== 0 &&
        this.$message({
          type: 'warning',
          message: msg
        })
        this.getActivityList()
    },

    // 上传图片方法
    handleAvatarSuccess(res, file) {
      console.log('上传成功', res, file)
      this.imageUrl = 'http://file.innopinenut.com/' + res.key
      this.formData.logoId = res.key
    },
    async beforeAvatarUpload(file) {
      // console.log("上传前");
      let { data } = await getUploadToken()
      // console.log(data);
      if (data == '' || data == null) {
        this.message.error('上传图片时获取Token失败!')
        return
      }
      this.postData.token = data.token
      this.postData.key = data.fid
    },
    //获取列表
    async getActivityList() {
      let { data, count } = await getActivityList(this.queryParams)
      this.list = data
      this.total = count
    },

    // 打开添加的窗口
    addActivity() {
      this.editOradd = 0
      let editOradd = this.editOradd
      this.$router.push({
        path: '/addactivity',
        query: { editOradd }
      })
    },
    // 打开编辑类别的窗口
    updateActivity(val) {
      // 根据获取id获取详情
      this.editOradd = 1
      let editOradd = this.editOradd
      let obj = val
      this.$router.push({
        path: '/addactivity',
        query: { editOradd, obj }
      })
    },
    // 确认
    btnOK() {
      this.$refs.perForm
        .validate()
        .then(() => {
          if (this.formData.activityId) {
            return updateActivity(this.formData)
          } else {
            return addActivity(this.formData)
          }
        })
        .then(() => {
          //  提示消息
          if (this.formData.activityId) {
            this.$message.success('编辑成功')
          } else {
            this.$message.success('新增成功')
          }
          this.getActivityList()

          this.showDialog = false
        })
    },

    //弹窗取消函数
    btnCancel() {
      this.imageUrl = ''
      this.formData = {}
      this.$refs.perForm.resetFields()
      this.showDialog = false
    },

    //删除
    async delActivity(row) {
      try {
        await this.$confirm('确定要删除该数据吗')
        console.log(row)
        await delActivity({
          activityId: row.activityId
        })
        const totalPage = Math.ceil(
          (this.total - 1) / this.queryParams.pageSize
        )
        this.queryParams.page =
          this.queryParams.page > totalPage ? totalPage : this.queryParams.page
        this.queryParams.page =
          this.queryParams.page < 1 ? 1 : this.queryParams.page
        this.getActivityList()
        this.$message.success('删除成功')
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>
  
  <style scoped>
</style>
  